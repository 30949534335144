import { useEffect, useState } from 'react';
import { products } from './data/goodsObject';
import Calculator, { validateEmail } from './tool/data';
import './App.css';


const tg = "lm099bot";
const payHost = "https://pay.lm99.pro/";


function App() {

  const [category, setCategory] = useState('');
  const [goods, setGoods] = useState('');
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [email, setEmail] = useState('');
  const [info, setInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState(0);
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    setCurrentDate(formattedDate);
    window.addEventListener("resize", setHtmlFontSize);
    setHtmlFontSize();
    return () => {
      window.removeEventListener("resize", setHtmlFontSize);
    }
  }, []);

  useEffect(() => {
    setTotal(Calculator.mul(price, first===1?.01:1));
  }, [price, first]);

  const setHtmlFontSize = () => {
    var fontSize = document.documentElement.clientWidth / 375 * 16;
    if (fontSize >= 36) {
      document.documentElement.style.fontSize = fontSize + "px";
    }
  }
  const updatePrice = (goods: string) => {
    if (goods) {
      products[category].forEach((item: any) => {
        if (item.name === goods) {
          setPrice(item.price);
        }
      })
    }
  }

  const clearGoods = () => {
    setGoods('');
    setPrice(0);
    setTotal(0);
  }

  const submitOrder = () => {
    if (!category || !goods) {
      alert('请选择项目');
      return;
    }

    if (!info) {
      alert('请输入资料');
      return;
    }

    if (!email) {
      alert('请输入邮箱');
      return;
    }
    if (!validateEmail(email)) {
      alert('请输入正确的邮箱');
      return;
    }

    setLoading(true);
    setTimeout(() => {
      window.location.href = `${payHost}?price=${total}&orderNo=${Math.random().toString(36).substr(2)}`;
    }, 1500);
  }

  return (
    <div className="App w-full h-full">

      <div>
        <img className='w-full' src="static/img/top.png" alt="frame" />
      </div>
      <div className='absolute w-full top-36'>
        <div className='bg-blue-500 p-2'>
          <h1 className='text-[20px] font-bold ml-2 text-center text-white'>全球社工库查档</h1>
        </div>
        <div className='bg-white p-2'>
          <div className='flex items-center'>
            <img className='h-6' src="static/img/notice.png" alt="notice" />
            <div className='font-bold'>公告</div>
          </div>

          <div className='text-blue-600 text-[14px] text-left'> 全球社工库成立的初衷是为了帮助他人更好的了解事实真相，我们整合全网查询资源渠道，您只需要告诉我们你所需查询的类目，我们就会以最快速给你匹配可靠的一手渠道.更节约成本,节约时间,某些程度上来讲,价格当然很重要,效率也是很多客户看重的！不成功不收取一分钱费用，珍惜客户信任，保护客户隐私是我们首要的从业准则。</div>

        </div>
        <div className='bg-white rounded p-2 mt-2'>
          <div className='flex'>
            <div className='text-green-500 text-[14px]'>更新时间：</div>
            <div className='text-[14px]'>{currentDate}</div>
            <div className='text-[14px] text-green-500 ml-2'>已经更新完毕</div>
          </div>
          <div className='flex text-[14px] mt-1'>
            <div>客服TG：</div>
            <div className='text-blue-600 font-medium'><a href={`https://t.me/${tg}`}>{`@${tg}`}</a></div>
          </div>
        </div>
        <div className='bg-blue-500 p-2'>
          <div className='text-[20px] font-bold ml-2 text-center text-white'>查档下单</div>
        </div>
        <div className='bg-white rounded p-2'>
          <div className='flex items-center w-full justify-between'>
            <div className='flex'>
              <div className='text-sm w-[70px] text-left'>分类</div>
              <div className='text-sm text-red-500'>*</div>
            </div>

            <div className='flex justify-end items-center'>
              <select className='w-full border-transparent border-none rounded text-right text-gray-600 p-1 focus:outline-none' onChange={(e) => {
                setCategory(e.target.value);
                clearGoods();
              }}>
                <option value=''>请选择分类</option>
                <option value="103">身份信息</option>
                <option value="104">在逃犯罪</option>
                <option value="105">通话记录</option>
                <option value="106">开房记录</option>
                <option value="107">车辆信息</option>
                <option value="108">银行信息</option>
                <option value="109">全国房产</option>
                <option value="120">定位轨迹</option>
                <option value="121">快递外卖</option>
                <option value="122">工商法人</option>
                <option value="123">查手机号</option>
                <option value="124">微信QQ支付宝</option>
              </select>
              <div className={`w-[10px] h-[10px] rounded-[15px] ml-1 ${category ? "bg-green-400" : "bg-red-400"}`}></div>
            </div>

          </div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='flex'>
              <div className='text-sm w-[70px] text-left'>项目</div>
              <div className='text-sm text-red-500'>*</div>
            </div>

            <div className='flex justify-end items-center'>
              <select className='w-full border-transparent border-none rounded text-right text-gray-600 p-1 focus:outline-none' onChange={(e) => {
                setGoods(e.target.value);
                updatePrice(e.target.value);
              }}>
                <option value=''>请选择项目</option>
                {
                  category &&
                  (products[category]).map((item: any) => {
                    return <option key={item.id} value={item.name}>{
                      item.name
                    }</option>
                  }
                  )}
              </select>
              <div className={`w-[10px] h-[10px] rounded-[15px] ml-1 ${goods ? "bg-green-400" : "bg-red-400"}`}></div>
            </div>

          </div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='text-sm w-[70px] text-left'>商品单价</div>
            <div className='flex items-center mr-1 text-[15px]'>
              <div className='mr-1'>{price}</div>
              <div className=''>USDT</div>
            </div>
          </div>
          <div className='flex items-center w-full justify-end mt-2 text-[15px]'>
            <input type='checkbox' value={first} className='mr-2' onChange={(e)=>{
              setFirst(Number(e.target.checked));
            }} />
            <div>最近一条信息</div>
          </div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='flex'>
              <div className='text-sm w-[70px] text-left'>填写资料</div>
              <div className='text-sm text-red-500 mr-2'>*</div>
            </div>
            <div className='rounded text-[14px] w-full'>
              <input type="text" className='w-full bg-gray-200 border-transparent border-none rounded text-right text-gray-600 p-2 focus:outline-none' placeholder='名字/身份证/手机号/QQ号 任意一项' onChange={(e) => {
                setInfo(e.target.value);
              }} />
            </div>
          </div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='flex'>
              <div className='text-sm w-[88px] text-left'>备注</div>

            </div>
            <div className='rounded text-[14px] w-full'>
              <input type="text" className='w-full bg-gray-200 border-transparent border-none rounded text-right text-gray-600 p-2 focus:outline-none' placeholder='如有其他需求请备注（选填）'/>
            </div>
          </div>
          <div className='flex items-center w-full justify-between mt-2'>
            <div className='flex'>
              <div className='text-sm w-[70px] text-left'>邮箱</div>
              <div className='text-sm text-red-500 mr-2'>*</div>
            </div>
            <div className='rounded text-[14px] w-full'>
              <input type="text" className='w-full bg-gray-200 border-transparent border-none rounded text-right text-gray-600 p-2 focus:outline-none' placeholder='请正确填写邮箱地址' onChange={(e) => {
                setEmail(e.target.value);
              }} />
            </div>
          </div>
        </div>
        <div className='flex rounded bg-white mt-2 p-2 justify-between items-center' >
          <div className='flex items-center justify-center'>
            <div className='text-[15px] text-left text-gray-600'>金额：</div>
            <div className='text-blue-500 text-[15px] font-medium'>{total} USDT</div>
          </div>
          <div className='bg-blue-400 text-white rounded-xl px-5 py-1 font-bold' onClick={submitOrder}>立即下单</div>
        </div>

      </div>

      {
        loading && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60">
            <div className="bg-white p-2 rounded-lg shadow-lg">
              <div className='m-2 p-2'>
                <div className='text-blue-400 font-bold'>提交中...</div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default App;
