import Decimal from "decimal.js";

export default class Calculator {
  static add(a: number, b: number): number {
    const num1 = new Decimal(a);
    const num2 = new Decimal(b);
    return num1.plus(num2).toNumber();
  }

  static sub(a: number, b: number): number {
    const num1 = new Decimal(a);
    const num2 = new Decimal(b);
    return num1.minus(num2).toNumber();
  }

  static mul(a: number, b: number): number {
    const num1 = new Decimal(a);
    const num2 = new Decimal(b);
    return num1.times(num2).toNumber();
  }

  static div(a: number, b: number): number {
    const num1 = new Decimal(a);
    const num2 = new Decimal(b);
    return num1.dividedBy(num2).toNumber();
  }
}



export function validateEmail(email: string) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

